import {AmbassadorInfo} from '@halp/foundation/Ambassadors';
import {Heading, Page, Stack, useBreakpoint} from '@halp/ui';
import {useUser, withUserProtectedRoute} from '@halp/foundation/Users';
import {CampaignsTable, Funnel, Summary} from '../modules/Trios';
import {constructServerSideProps} from '../utils/ssr';
import {AmbassadorMetrics} from '../modules/AmbassadorMetrics';
import {ReferralLinkButton} from '../modules';

function TriosPartnerIndex() {
	const {user} = useUser();

	if (!user?.partnerProfile) {
		return null;
	}

	return (
		<Page>
			<Stack fullWidth direction="column" spacing="xl" alignItems="flex-start">
				<Stack
					fullWidth
					direction="column"
					spacing="md"
					alignItems="flex-start"
				>
					<AmbassadorInfo businessName="triOS" />
					<Summary />
				</Stack>
				<Stack
					fullWidth
					direction="column"
					spacing="md"
					alignItems="flex-start"
				>
					<Heading type="h3">Students</Heading>
					<Funnel />
				</Stack>
				<Stack
					fullWidth
					direction="column"
					spacing="md"
					alignItems="flex-start"
				>
					<Heading type="h3">Campaigns</Heading>
					<CampaignsTable />
				</Stack>
			</Stack>
		</Page>
	);
}

function AmbassadorIndex() {
	const {user} = useUser();
	const isMobile = useBreakpoint({max: 'md'});

	if (user?.partnerProfile) {
		return <TriosPartnerIndex />;
	} else if (user?.ambassadorProfile) {
		const firstCampaignSlug = user.ambassadorProfile.signupCampaigns[0]?.slug;
		const orgHandle = user.ambassadorProfile.org.handle;

		const referralLinkButton =
			firstCampaignSlug && orgHandle ? (
				<ReferralLinkButton
					campaignSlug={firstCampaignSlug}
					orgHandle={orgHandle}
				/>
			) : null;

		return (
			<Page>
				<Stack
					direction="column"
					alignItems="flex-start"
					justifyContent="flex-start"
					spacing="lg"
				>
					<Stack
						direction={isMobile ? 'column' : 'row'}
						spacing="md"
						alignItems={isMobile ? 'center' : 'center'}
						justifyContent={isMobile ? 'center' : 'flex-start'}
						fullWidth
					>
						<AmbassadorInfo
							businessName={user.ambassadorProfile.businessName}
							publicId={user.ambassadorProfile.publicId}
							name={user?.name}
							email={user?.email}
						/>
						{referralLinkButton}
					</Stack>
					<AmbassadorMetrics />
				</Stack>
			</Page>
		);
	}

	return null;
}

export default withUserProtectedRoute(AmbassadorIndex, {
	role: ['partner', 'ambassador'],
});
export const getServerSideProps = constructServerSideProps({
	dictionaries: ['partner', 'ambassador'],
});
