import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {
	DOMESTIC_FUNNEL_STAGE_ORDER,
	DOMESTIC_METRIC_TITLES,
	FUNNEL_STAGE_ORDER,
	METRIC_COLORS,
	METRIC_TITLES,
} from 'utils/constants';
import {Icon, Heading, Paragraph, Stack, Surface} from '@halp/ui';
import {useQuery} from '@halp/api/graphql';
import style from './AmbassadorMetrics.module.css';
import {AmbassadorDashboardDocument} from './AmbassadorDashboard.query';

interface Metrics {
	signup: number;
	verification: number;
	getStarted: number;
	discover: number;
	prep: number;
	submit: number;
	accepted: number;
	visa: number;
	setUp: number;
	placed: number;
}

export function AmbassadorMetrics() {
	const {data} = useQuery(AmbassadorDashboardDocument);

	const metrics = data?.session?.ambassadorProfile?.studentsByFunnelStage;

	if (!metrics) {
		return null;
	}

	const funnelStageOrder =
		data?.session?.ambassadorProfile?.type === 'AMBASSADOR'
			? DOMESTIC_FUNNEL_STAGE_ORDER
			: FUNNEL_STAGE_ORDER;

	const metricTitles =
		data?.session?.ambassadorProfile?.type === 'AMBASSADOR'
			? DOMESTIC_METRIC_TITLES
			: METRIC_TITLES;

	return (
		<Stack
			direction="column"
			alignItems="stretch"
			justifyContent="flex-start"
			spacing="md"
		>
			<Heading type="h3">Total Converted Numbers</Heading>
			<Stack
				direction="row"
				alignItems="stretch"
				justifyContent="flex-start"
				wrap="wrap"
				spacing="md"
			>
				{metrics
					? funnelStageOrder.map((funnelStage, index) => {
							return (
								<Stack
									direction="row"
									justifyContent="flex-start"
									spacing="xs"
									key={funnelStage}
								>
									<Surface
										color={METRIC_COLORS[funnelStage]}
										className={style.MetricCard}
									>
										<Stack
											direction="column"
											alignItems="flex-start"
											justifyContent="flex-start"
											spacing="xs"
										>
											<Heading type="h3" color={METRIC_COLORS[funnelStage]}>
												{index + 1}
											</Heading>
											<Paragraph color="grey">
												{metricTitles[funnelStage]}
											</Paragraph>
											<Heading type="h4">
												{metrics[funnelStage as keyof Metrics]}
											</Heading>
										</Stack>
									</Surface>
									{index !== funnelStageOrder.length - 1 ? (
										<Icon icon={faAngleRight} color="grey" />
									) : null}
								</Stack>
							);
						})
					: null}
			</Stack>
		</Stack>
	);
}
